<template>
<div>
  <studio />
</div>
</template>

<script>
import Studio from '../tests/TestComposerAssetsOverride.vue'

export default {
  components: { 
    Studio 
  },
}
</script>

<style>

</style>